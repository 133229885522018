<template>
    <!-- BEGIN: Content-->
    <div class="mt-0">
      <div class="flex">
        <div class="section_title"><span class="big-size weight-bold">{{ $store.state.loader_text }}&nbsp;&nbsp;</span>
          <span v-if="orgname !== null" style="font-size:11px !important;margin:auto !important;"> | {{ orgname.name }}</span>
          <div class="load"><v-progress-circular
            color="primary"
            indeterminate
            :size="18"
            :width="3"
            v-show="loader"
          ></v-progress-circular></div>
        </div>
        <div style="display:flex;align-items:center;justify-content:space-between;flex-direction:row">
          <v-btn
            color="primary"
            class="ma-1 white--text"
            @click="exportpdf"
            depressed
            :loading="loadingpdf"
            :disabled="disabledpdf"
          >
            Export Pdf
            <v-icon
              right
              dark
            >
              mdi-file-pdf-box
            </v-icon>
          </v-btn>

          <v-btn
            color="secondary"
            class="ma-1 white--text"
            @click="exportexcel"
            depressed
            :loading="loadingexcel"
            :disabled="disabledexel"
          >
            Export Excel
            <v-icon
              right
              dark
            >
              mdi-file-excel
            </v-icon>
          </v-btn>
        </div>
      </div>
      <div class="customerStatement">
        <v-container fluid style="padding-top:0 !important;padding-bottom:0 !important">
          <v-row>
            <v-col cols="12" class="pb-0 mb-0 px-0">
              <div class="summaryAlert px-4">
             <!--    <div class="mb-2">
                  <span class="summaryTitle">Payment Summary <span v-if="orgname !== null">| {{ orgname.name }}</span></span>
                </div> -->
                <div class="summaryTotals mt-2">
                  <div class="totals">
                    <span class="font-normal">Cummulative Total</span>
                    <span class="font-medium">KES{{ 
                          allstatements.reduce((acc, val) => acc + val.amount, 0) | currency(' ')
                        }}</span>
                  </div>
                  <div class="mx-5 arrow-right"></div>
                  <div class="totals">
                    <span class="font-normal">Cummulative Balance</span>
                    <span class="font-medium">KES{{ 

                              allstatements.reduce( (acc, item) => 
                              {  
                                let total_amt = 0;
                                

                                total_amt = (
                                  (item.amount) - 
                                  ((item.payments.reduce((acc1, val) => acc1 + parseFloat(val.paidamount), 0))+item.creditnotes.reduce((acc1, val) => acc1 + parseFloat(val.amount), 0)+(item.witholdingtax_sum_amount !== null ? parseFloat(item.witholdingtax_sum_amount) : 0))+
                                  ((item.debitnotes.reduce((acc1, val) => acc1 + parseFloat(val.amount), 0)
                                  )+(item.payments.reduce((acc1, val) => acc1 + val.reversals.reduce((acc2, val2) => acc2 + parseFloat(val2.amount), 0), 0)
                                  ))
                                  ) > 0 ? (
                                  (item.amount) - 
                                  ((item.payments.reduce((acc1, val) => acc1 + parseFloat(val.paidamount), 0))+item.creditnotes.reduce((acc1, val) => acc1 + parseFloat(val.amount), 0)+(item.witholdingtax_sum_amount !== null ? parseFloat(item.witholdingtax_sum_amount) : 0))+
                                  ((item.debitnotes.reduce((acc1, val) => acc1 + parseFloat(val.amount), 0)
                                  )+(item.payments.reduce((acc1, val) => acc1 + val.reversals.reduce((acc2, val2) => acc2 + parseFloat(val2.amount), 0), 0)
                                  ))
                                  ) : 0;

                                return acc += total_amt;

                              }, 0) | currency('')
                            
                            }}</span>
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>

          <v-row style="position:relative !important;z-index:1 !important;margin-bottom:0 !important">
            <v-col cols="12" class="py-0 px-0">
              <v-card class="card-shadow py-3">
                <v-card-title class="pt-0 mt-0" style="display:flex;align-items:center;justify-content:space-between">
                  <Search/>
                  <v-col md="2" cols="2" sm="2" class="px-1" style="padding-top:0 !important;padding-bottom:0 !important;display:flex;">
                    <v-text-field
                          v-model="searchfilter"
                          label="Search"
                          solo
                          hide-details="auto"
                        ></v-text-field>
                  </v-col>
                </v-card-title>
                <v-card-text class="px-0 py-0 cardtext1">
                  <v-virtual-scroll
                    :items="allstatements"
                    :item-height="20"
                    renderless 
                    id="virtualscroll"
                   >
                    <template v-slot:default="{ item }">
                        <div class="tcell">{{ moment(item.date).format('MMM Do YYYY') }}</div>
                        <div class="tcell">
                          <p class="text--primary mb-0" style="text-transform:uppercase;font-size:10px !important;font-weight:500 !important;" v-if="item.invoice_no != null">{{ item.invoice_no }}</p>
                        </div>
                        <div class="tcell">{{ item.status }}</div>
                        <div class="tcell">
                          <p class="text--primary mb-0" style="text-transform:uppercase;font-size:10px !important;font-weight:500 !important;">{{ item.amount | currency(' ') }}</p>
                        </div>

                        <div class="tcell">
                          <div class="text--primary mb-0" style="text-transform:uppercase;font-size:10px !important;font-weight:500 !important;" v-if="item.payments.length > 0">
                            <span v-for="itm in item.payments">{{ itm.reference_no }} <span class="pipe"> </span> </span>
                          </div>
                        </div>
                        <div class="tcell">
                          <div class="text--primary mb-0" style="text-transform:uppercase;font-size:10px !important;font-weight:500 !important;" v-if="item.payments.length > 0">
                            <span v-for="itm in item.payments">{{ itm.paidamount | currency(' ') }} <span class="pipe"> </span> </span>
                          </div>
                        </div>

                        <div class="tcell">
                          <div class="text--primary mb-0" style="text-transform:uppercase;font-size:10px !important;font-weight:500 !important;" v-if="item.creditnotes.length > 0">
                            <span v-for="itm in item.creditnotes">{{ itm.credit_note_no }} <span class="pipe"> </span></span>
                          </div>
                        </div>
                        <div class="tcell">
                          <div class="text--primary mb-0" style="text-transform:uppercase;font-size:10px !important;font-weight:500 !important;" v-if="item.creditnotes.length > 0">
                            <span v-for="itm in item.creditnotes">{{ itm.amount | currency(' ') }} <span class="pipe"> </span></span>
                          </div>
                        </div>

                        <div class="tcell">
                          <div class="text--primary mb-0" style="text-transform:uppercase;font-size:10px !important;font-weight:500 !important;" v-if="item.debitnotes.length > 0">
                            <span v-for="itm in item.debitnotes">{{ itm.debit_note_no }} <span class="pipe"> </span></span>
                          </div>
                        </div>
                        <div class="tcell">
                          <p class="text--primary mb-0" style="text-transform:uppercase;font-size:10px !important;font-weight:500 !important;" v-if="item.debitnotes.length > 0"><span v-for="itm in item.debitnotes">{{ itm.amount | currency(' ') }} <span class="pipe">| </span></span></p>
                        </div>

                        <div class="tcell">
                          <p class="text--primary mb-0" style="text-transform:uppercase;font-size:10px !important;font-weight:500 !important;">
                            
                            {{ 

                              (
                              (item.amount) - 
                              ((item.payments.reduce((acc1, val) => acc1 + parseFloat(val.paidamount), 0))+item.creditnotes.reduce((acc1, val) => acc1 + parseFloat(val.amount), 0)+(item.witholdingtax_sum_amount !== null ? parseFloat(item.witholdingtax_sum_amount) : 0))+
                              ((item.debitnotes.reduce((acc1, val) => acc1 + parseFloat(val.amount), 0)
                              )+(item.payments.reduce((acc1, val) => acc1 + val.reversals.reduce((acc2, val2) => acc2 + parseFloat(val2.amount), 0), 0)
                              ))
                              ) > 0 ? (
                              (item.amount) - 
                              ((item.payments.reduce((acc1, val) => acc1 + parseFloat(val.paidamount), 0))+item.creditnotes.reduce((acc1, val) => acc1 + parseFloat(val.amount), 0)+(item.witholdingtax_sum_amount !== null ? parseFloat(item.witholdingtax_sum_amount) : 0))+
                              ((item.debitnotes.reduce((acc1, val) => acc1 + parseFloat(val.amount), 0)
                              )+(item.payments.reduce((acc1, val) => acc1 + val.reversals.reduce((acc2, val2) => acc2 + parseFloat(val2.amount), 0), 0)
                              ))
                              ) : 0
                               | currency(' ')
                            }}

                          </p>
                        </div>

                    </template>
                  </v-virtual-scroll>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
</template>
<style>
  .customerStatement .v-virtual-scroll__item{
    width:100%;
    display: grid !important;
     grid-auto-columns: minmax(9.090909%, 9.090909%) !important;
     /*grid-template-columns: 6.11% 21.11% 12.11% 12.11% 14.11% 15.11% 11.11% 8.22%;*/
     grid-auto-flow: column !important;
     grid-gap: 0px;
  }
</style>
<script>
import axios from 'axios'
import Vue from 'vue'
import {ability} from '@/services/ability'
import Search from '@/components/statement/search'
import $ from 'jquery';

export default {
    name:"customerStatement",
    components: { Search },
    data(){
        return {
          disabledpdf: false,
          disabledexel: false,
          searchfilter: '',
          search: '',
          dialog: false,
          searchdialog: false,
          loading: false,
          loadingpdf: false,
          loadingexcel: false,
          fields: [
            { text: 'DATE',value: 'date' },
            { text: 'INV#', value: 'invno' },
            { text: 'STATUS', value: 'status' },
            { text: 'INV AMT(KES)', value: 'invoiceamount' },
            { text: 'PAYT#', value: 'paymentno' },
            { text: 'PAYT AMT(KES)', value: 'paymentamount' },
            { text: 'CN#', value: 'crno' },
            { text: 'CN AMT(KES)', value: 'creditamount' },
            { text: 'DN#', value: 'drno' },
            { text: 'DN AMT(KES)', value: 'debitamount' },
            { text: 'BAL(KES)', value: 'balance' },
           ],
           reversefields: [
             {
              text: 'DATE',
              align: 'start',
              value: 'date',
            },
            { text: 'Time', value: 'time' },
            { text: 'Ref', value: 'ref' },
            { text: 'From', value: 'from' },
            { text: 'To', value: 'to' },
            { text: 'Passenger', value: 'passenger' },
            { text: 'Amount', value: 'amount' },

           ],
          item: {
            organisation_id: null,
            project_id: null
           }, 
        }
    },
    beforeMount() {
      if(!ability.can('view-reports', 'all')) {
        this.$router.push({ name: 'Dashboard' })
        return
      }
    },
    serverPrefetch () {
    },
    mounted(){

      this.$store.state.loader_text = "Customer Statements"

      this.$store.state.statementorganisation = null
      if(!this.minorganisations || this.minorganisations == 0) {
        this.getOrganisation()
      }
      if(!this.allstatements || this.allstatements == 0) {
        this.getStatement()
      }

      let headertitle = `<div class="v-virtual-scroll__container"><div class="v-virtual-scroll__item" style="top: 0px;">`;
      this.fields.map(function(value, key) {
           headertitle +=`<div class="thead">${value.text}</div>`;
         });
      headertitle +=`</div></div>`;
      $('.v-virtual-scroll').prepend(headertitle)

    },
    computed: {
      loader() {
        return this.$store.state.tableloader
      },
      statements () {
        return this.$store.state.statements.filter(item => {

          this.start = 0

          let invoice_no = "";
          let payment = [];
          let credit = [];
          let debit = [];
          let organisation = "";
          let amount = "";
          let date = "";
          if(item.organisations.length > 0) {
            organisation = item.organisations[0].name
          }
          if(item.payments.length > 0) {
            payment = item.payments
          }
          if(item.creditnotes.length > 0) {
            credit = item.creditnotes
          }
          if(item.debitnotes.length > 0) {
            debit = item.debitnotes
          }
          if(item.invoice_no !== null) {
            invoice_no = item.invoice_no
          }
          if(item.amount !== null) {
            amount = item.amount
          }
          if(item.date !== null) {
            date = item.date
          }
          return organisation.toLowerCase().includes(this.searchfilter.toLowerCase()) || invoice_no.toLowerCase().includes(this.searchfilter.toLowerCase()) 
          // || payment.some(itm => itm.reference_no !== null ? itm.reference_no.includes(this.searchfilter.toLowerCase()) : true) || credit.some(itm => itm.credit_note_no !== null ? itm.credit_note_no.includes(this.searchfilter.toLowerCase()) : true) || debit.some(itm => itm.debit_note_no !== null ? itm.debit_note_no.includes(this.searchfilter.toLowerCase()) : true)
        })
      },
      allstatements () {
        return this.statements
      },
      orgname () {
        return this.$store.state.statementorganisation
      },
      minorganisations () {
        return this.$store.state.minorganisations
      },
      statementdata () {
        return this.$store.state.statementdata
      }
    },
    methods:{
      getOrganisation(){
        // this.$store.state.tableloader = true
        axios.get('/minorganisations').then(response=>{
            // this.$store.state.tableloader = false
            if(response.data.status == parseInt(200)) {
              this.$store.state.minorganisations = response.data.organisations
            }
        }).catch(error=>{
            // this.$store.state.tableloader = false
            console.log(error)
        })
      },
      getStatement(){

        this.$store.state.tableloader = true

        axios.get('/organisations/allstatement').then(response=>{
          this.$store.state.tableloader = false
          if(response.data.status == parseInt(200)) {
            this.$store.state.statements = response.data.statements
          }
          
        })
        .catch(error => {
          this.$store.state.tableloader = false
          console.log(error)
        })

      },
      exportexcel(){

        this.loadingexcel = true
        this.disabledexel = true

        const config = { headers: { 'Content-Type': 'multipart/form-data' }, responseType: 'arraybuffer' };
          let fd = new FormData();
          fd.append('organisation_id',this.statementdata.organisation_id)
          fd.append('project_id',this.statementdata.project_id)
          fd.append('start',this.statementdata.start)
          fd.append('end',this.statementdata.end)

        axios.post('/statement/export/fileexcel', fd, config).then(response=>{
          this.loadingexcel = false
          this.disabledexel = false
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement('a');
          fileLink.href = fileURL;
          fileLink.setAttribute('download', 'customer_statement.xlsx');
         document.body.appendChild(fileLink);
         fileLink.click();
          
        })
        .catch(error => {
          this.loadingexcel = false
          this.disabledexel = false
          console.log(error)
        })
      },
      exportpdf(){

        this.loadingpdf = true
        this.disabledpdf = true
        
        const config = { headers: { 'Content-Type': 'multipart/form-data' }, responseType: 'blob' };
          let fd = new FormData();
          fd.append('organisation_id',this.statementdata.organisation_id)
          fd.append('project_id',this.statementdata.project_id)
          fd.append('start',this.statementdata.start)
          fd.append('end',this.statementdata.end)

        axios.post('/statement/export/filepdf', fd, config).then(response=>{
         this.loadingpdf = false
         this.disabledpdf = false
         var fileURL = window.URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}));
         var fileLink = document.createElement('a');
         fileLink.href = fileURL;
         fileLink.setAttribute('download', 'customer_statement.pdf');
         document.body.appendChild(fileLink);
         fileLink.click();
          
        })
        .catch(error => {
          this.loadingpdf = false
          this.disabledpdf = false
          console.log(error)
        })
      },

    }
}
</script>