<template>
	<div>
    <v-dialog
      max-width="420px"
      v-model="show"
      >
        <v-card>
          <v-form  enctype="multipart/form-data" v-model="valid" ref="form">
            <v-card-title class="d-flex justify-space-between">
              <div class="loadflex"><span class="text-h6">Tax Payment for</span>&nbsp;&nbsp;<span style="font-size:14px !important;color:#888888">{{ item.tolinkreceipt }}&nbsp;&nbsp;</span><div class="load"><v-progress-circular
                    color="primary"
                    indeterminate
                    :size="18"
                    :width="3"
                    v-show="loader"></v-progress-circular></div></div>
            </v-card-title>

            <v-card-text>
                <v-container class="mt-0 mx-0 px-0 mb-0">
                  <v-row>
                   <!--  <v-col
                        cols="12"
                        sm="12"
                        md="12"
                      >
                        <v-autocomplete
                          :items="witholdings"
                          label="Select witholding"
                          v-model="item.witholding_tax_no_id"
                          item-text="witholding_tax_no"
                          item-value="id"
                          :search-input.sync="synctax"
                          hide-details
                        >
                        <template slot="selection" slot-scope="{ item, selected }">
                            {{ item.witholding_tax_no }} | {{ item.invoice_no }}
                        </template>
                        <template slot="item" slot-scope="{ item, tile }">
                            {{ item.witholding_tax_no }} | {{ item.invoice_no }}
                        </template>
                        </v-autocomplete>
                      </v-col> -->
                    <v-col sm="12" md="12" cols="12">
                      <v-text-field
                          label="Amount*"
                          v-model="item.amount"
                          :rules="fieldRules"
                          hide-details
                        ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                      >
                      <v-menu
                        ref="menu2"
                        v-model="menu2"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            label="Date*"
                            v-bind="attrs"
                            :value="timepickerdate"
                            v-on="on"
                            hide-details
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="date"
                          no-title
                          scrollable
                          @input="menu2 = false"
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                            text
                            color="#f9780d"
                            @click="menu2 = false"
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="#f9780d"
                            @click="$refs.menu2.save(date)"
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                      <v-col sm="12" md="12" cols="12">
                        <v-text-field
                            label="Reference no"
                            v-model="item.reference_no"
                            hide-details
                          ></v-text-field>
                      </v-col>
                  </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
                <v-btn
                  color="default darken-1"
                  text
                  @click.stop="show = false"
                >
                  Close
                </v-btn>
                <v-btn
                  color="primary darken-1"
                  text
                  :loading="loading"
                  :disabled="!valid"
                  @click="submit"
                >
                  Submit
                </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
    </v-dialog>
	</div>
</template>

<style>
  .modal-paywitholding{
    max-width:420px !important;
  }
</style>

<script>
import axios from 'axios'
import moment from 'moment'
import { format, parseISO } from 'date-fns'
import Vue from 'vue'

export default {
	name: 'paysinglewitholding',
	components: {
	},
	data: () => ({
		fieldRules: [
          (v) => !!v || 'Field is required'
          ],
          menu2: false,
          date: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
        valid: true,
        synctax: null,
        paymentmodes: null,
        isBusy: false,
        loading: false,
        disabled: false,
        item:{
           witholding_tax_no_id: '',
           reference_no: "",
           amount: "",
           date: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
           tolinkreceipt: ""
        },
       modes: []
	}),
	methods: {

    submit(){
      this.loading = true

      const config = { headers: { 'Content-Type': 'multipart/form-data' } };
      let fd = new FormData();
      fd.append('witholding_tax_no_id',this.item.witholding_tax_no_id)
      fd.append('amount',this.item.amount)
      fd.append('reference_no',this.item.reference_no)
      fd.append('date',this.item.date)

      axios.post('/tax-payment', fd, config).then(response=>{
          this.loading = false
        if(response.data.status == parseInt(200)) {    
          let found = this.$store.state.witholdingpayments.find(data => data.id == response.data.payment.id);
          if (!found) {
            this.$store.state.witholdingpayments.unshift(response.data.payment)
          }      
          this.$toast.success('Payment submitted successfully','Success',{position: 'topRight',timeout: 7000,})
          this.clear()
          this.show = false
        }
          
        })
        .catch(() => {
          this.loading = false
        })
    },

    clear() {
      this.item.witholding_tax_no_id = null
      this.item.amount = ""
      this.item.reference_no = ""
      this.item.date = format(parseISO(new Date().toISOString()), 'yyyy-MM-dd')
    }
	},
  watch: {
      data: function(newVal, oldVal) {
        if(newVal !== null) {
          this.item.id = newVal.id
          this.item.witholding_tax_no_id = newVal.id
          this.item.tolinkreceipt = newVal.tolinkreceipt
        }
      },
      show: function (newVal,oldVal) {
        if(newVal == true) {}
      }
  },
	props: {
     value: Boolean
  },
  computed: {
    loader() {
      return this.$store.state.componentloader
    },
    data() {
      return this.$store.state.paysinglewitholdingdata
    },
    timepickerdate () {
        return this.date ? moment(this.date).format('Do MMM YYYY') : ''
      },
    show: {
      get () {
        return this.value
      },
      set (value) {
         this.$emit('input', value)
      }
    }
  },
	mounted() {
  },
	created() {}
}
</script>