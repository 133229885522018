<template>
    <!-- BEGIN: Content-->
    <div class="mt-0">
      <div class="flex">
        <div class="section_title"><span class="big-size weight-bold">{{ $store.state.loader_text }}&nbsp;&nbsp;</span>
          <div class="load"><v-progress-circular
            color="primary"
            indeterminate
            :size="18"
            :width="3"
            v-show="loader"
          ></v-progress-circular></div>
        </div>

        <div style="display:flex;align-items:center;justify-content:space-between;flex-direction:row">
          <v-text-field
              v-model="searchfilter"
              label="Search"
              solo
              hide-details="auto"
              class="search_"
            ></v-text-field>
          <v-btn
            color="#000000"
            class="ma-1 white--text"
            depressed
            @click="newwithold"
          >
            Add
            <v-icon
              right
              dark
            >
              mdi-plus
            </v-icon>
          </v-btn>
        </div>
      </div>
      <div class="witholdingList">
        <v-container fluid style="padding-top:0 !important;padding-bottom:0 !important">
          <v-row style="margin-top:0 !important;margin-bottom:0 !important">
            <v-col md="12" sm="12" class="my-1 py-0 px-0">
              <v-card class="card-shadow py-0">
                <v-card-title class="mt-0 pt-0">
                  <v-tabs
                    v-model="tab"
                    horizontal
                   >
                    <v-tab link :to='{name:"witholdingsList"}'>Witholding Tax</v-tab>
                    <v-tab class="mx-2" link :to='{name:"taxPaymentList"}'>Tax Payments</v-tab>
                  </v-tabs>
                </v-card-title>
                <v-card-text class="px-0 pt-1 pb-3 cardtext_tax">
                  <div class="empty-state" v-if="allwitholdings.length === 0 && !loader">
                    <div class="empty-state__content">
                      <div class="empty-state__icon">
                        <v-img src="@/assets/icon.png" alt=""></v-img>
                      </div>
                      <div class="empty-state__message">No records available.</div>
                    </div>
                  </div>

                  <v-virtual-scroll
                    :items="allwitholdings"
                    :item-height="20"
                    renderless 
                    id="virtualscroll"
                   >
                    <template v-slot:default="{ item }">
                        <div class="tcell">{{ moment(item.date).format('MMM Do YYYY') }}</div>
                        <div class="tcell"><span style="color:#F98525 !important;text-decoration:none !important">{{ item.witholding_tax_no }}</span></div>
                        <div class="tcell">
                          <span v-if="item.organisation_name !== null">{{ item.organisation_name }}</span>
                          <span v-else>NA</span>
                        </div>
                        <div class="tcell">
                          <span v-if="item.invoice_no !== null">{{ item.invoice_no }}</span>
                        </div>
                        <div class="tcell">KES<span style="font-weight:600 !important">{{ item.amount | currency(' ') }}</span></div>
                        <div class="tcell">{{ item.status }}</div>
                        <div class="tcell">
                          <v-menu offset-x left bottom origin="center center" transition="scale-transition">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                  icon
                                  v-bind="attrs"
                                  small
                                  v-on="on">
                                <v-icon>mdi-arrow-down-drop-circle-outline</v-icon>
                              </v-btn>
                            </template>
                            
                            <v-list-item-group
                              color="primary"
                              class="actions"
                              >

                              <v-list-item
                                link
                                @click="paytax(item.id,item.witholding_tax_no)"
                                v-if="item.status == 'Outstanding'"
                                >
                                    <v-list-item-icon>
                                      <v-icon>mdi-record-circle-outline</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                      <v-list-item-title>Pay Tax</v-list-item-title>
                                    </v-list-item-content>
                              </v-list-item>

                              <v-list-item
                                link
                                @click="deleteWitholding(item.id)"
                                >
                                    <v-list-item-icon>
                                      <v-icon>mdi-delete</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                      <v-list-item-title>Remove</v-list-item-title>
                                    </v-list-item-content>
                              </v-list-item>

                            </v-list-item-group>
                          </v-menu>
                        </div>
                    </template>
                  </v-virtual-scroll>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
       
        <!-- components -->
        <WitholdInvoice v-model="witholddialog"/>
        <PaySingleWitholding v-model="paywitholdingdialog"/>

      </div>
    </div>

</template>
<style>
  .witholdingList .v-virtual-scroll__item{
    width:100%;
    display: grid !important;

     grid-auto-columns: minmax(14.2857%, 14.2857%) !important;
     /*grid-template-columns: 12.5% 10.5% 10.5% 18.5% 10.5% 12.5% 12.5% 12.5%;*/
     grid-auto-flow: column !important;
     grid-gap: 0px;
  }

  .v-tabs-bar,.v-tabs-bar__content{
    background-color: #fff !important;
  }
  .v-tabs-items{
    background-color: #fff !important;
    margin-top:.3rem !important;
  }
  .witholdingList .v-slide-group__content {
    padding: 1rem 0rem !important;
  }
  .theme--light.v-tabs>.v-tabs-bar,.v-tabs-slider{
    background: transparent !important;
  }
  a.v-tab{
    font-size: .65rem !important;
  }
  .theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active){
    background:#f8f8f8;
  }
</style>
<style scoped>

    .v-tabs ::v-deep .v-tabs-slider-wrapper {
      transition: none !important;
    }
    .v-tabs-slider-wrapper{
      display: none !important;
    }
    .v-slide-group__content{
      width:100% !important;
      justify-content: flex-end !important;
    }
    .v-tab{
      padding:.2rem 1.5rem !important;
      border-radius: 50px !important;
      height:1.8rem !important;
      
    }
    .v-tab.v-tab--active{
      background:#DEC7FF;
    }

</style>
<script>
import axios from 'axios'
import {ability} from '@/services/ability'
import $ from 'jquery';
import WitholdInvoice from '@/components/witholding/witholdinvoice'
import PaySingleWitholding from '@/components/witholding/paysinglewitholding'

export default {
    name:"witholdingsList",
    components: {WitholdInvoice, PaySingleWitholding},
    data(){
        return {
          fieldRules: [
          (v) => !!v || 'Field is required'
          ],
          emailRules: [
          (v) => !!v || 'E-mail is required',
          (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid'
          ],
          searchfilter:'',
          search:null,
          valid: true,
          tab: null,
          witholddialog: false,
          searchcategory:null,
          searchinvoice:null,
          searchstatus:null,
          dialog: false,
          tableloader: false,
          updatedialog: false,
          paywitholdingdialog: false,

          fields: [
             {
              text: 'DATE',
              align: 'start',
              value: 'date',
            },
            { text: 'REF #', value: 'referenceno' },
            { text: 'ORGANISATION', value: 'customer' },
            { text: 'INVOICE#', value: 'invoice' },
            { text: 'AMOUNT', value: 'amount' },
            { text: 'STATUS', value: 'status' },
            { text: 'ACTION', value: 'action' },
           ],
          item:{
              invoice_id: "",
              id: "",
              tolinkreceipt: "",
              _method:"patch"
          },
            isBusy: false,
            loading: false,
           disabled: false,
           orgCategory: [],
           orgType: [],
           organisation: [],
           height: '100%',
            rowHeight: 25,
            perPage: 50,
            start: 0,
            timeout: null ,
            selectedItem: false,
            invoices: []
        }
    },
    beforeMount() {
      if(!ability.can('organisation-management', 'all')) {
        this.$router.push({ name: 'Dashboard' })
        return
      }
    },
    mounted(){

      this.$store.state.loader_text = "Witholding Tax"

      if(!this.allwitholdings || this.allwitholdings.length == 0) {
        this.getWitholdingtax()
      }

      let headertitle = `<div class="v-virtual-scroll__container"><div class="v-virtual-scroll__item" style="top: 0px;">`;
      this.fields.map(function(value, key) {
           headertitle +=`<div class="thead">${value.text}</div>`;
         });
      headertitle +=`</div></div>`;
      $('.v-virtual-scroll').prepend(headertitle)

    },
    serverPrefetch () {
    },
    computed: {
      loader() {
        return this.$store.state.tableloader
      },
      witholdings() {
        return this.$store.state.allwitholdings.filter(item => {

          this.start = 0

          let invoice_no = "";
          let witholding_tax_no = "";
          let reference_no = "";
          let organisation = "";

          if(item.organisation != null) {
            organisation = item.organisation.name
          }
          if(item.witholding_tax_no !== null) {
            witholding_tax_no = item.witholding_tax_no
          }
          if(item.reference_no !== null) {
            reference_no = item.reference_no
          }
          if(item.invoice_no !== null) {
            invoice_no = item.invoice_no
          }
          return witholding_tax_no.toLowerCase().includes(this.searchfilter.toLowerCase()) || invoice_no.toLowerCase().includes(this.searchfilter.toLowerCase()) || organisation.toLowerCase().includes(this.searchfilter.toLowerCase()) || reference_no.toLowerCase().includes(this.searchfilter.toLowerCase())
        })
      },
      allwitholdings() {
        return this.witholdings
      },
    },
    methods:{

      linkpayment(id, organisationId, paymentNo) {
        this.$store.state.linkpaymentdata = {
          id: id,
          organisationid: organisationId,
          tolinkreceipt: paymentNo
        }
        this.linkpaymentdialog = true
      },

      paytax(id,witholding_tax_no) {
        this.$store.state.paysinglewitholdingdata = {
          id: id,
          tolinkreceipt: witholding_tax_no
        }
        this.paywitholdingdialog = true
      },

      newwithold() {
        this.witholddialog = true
      },

      getWitholdingtax(){
        this.$store.state.tableloader = true
        this.$store
          .dispatch('getWitholdingtax')
          .then(() => {
            this.$store.state.tableloader = false
          })
          .catch(() => {
            this.$store.state.tableloader = false
          })
      },

      deleteWitholding(id){
            if(confirm("Are you sure to remove witholding ?")){
                axios.delete(`/witholding-tax/${id}`).then(response=>{
                  let index = this.$store.state.allwitholdings.findIndex(data => data.id == response.data.witholding.id);
                  if (index > -1) {
                    this.$store.state.allwitholdings.splice(index, 1);
                  }
                  this.$toast.success('witholding tax removed successfully','Success',{position: 'topRight',timeout: 7000,})
                }).catch(error=>{
                    console.log(error)
                })
            }
        }
    }
}
</script>